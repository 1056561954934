// Dependencies
import React from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import Layout from "../components/Layout";

const PageTemplate = ({ pageContext }) => {

  return (
    <Layout>
      {pageContext.title}
    </Layout>
  )
}

// PageTemplate.propTypes = {
//   data: PropTypes.object.isRequired
// }

export default PageTemplate

// export const PageTemplatePageQuery = graphql`
//   query PageTemplateQuery($slug: String!) {
//     contentfulPage(slug: {eq: $slug}) {
//       title
//       slug
//     }
//   }
// `
